import { SelectionModel } from '@angular/cdk/collections';
import { CdkColumnDef } from '@angular/cdk/table';
import {
  Component,
  ContentChild,
  EventEmitter,
  Input,
  OnInit,
  Output,
  TemplateRef,
  ViewChild,
} from '@angular/core';
import { MatCheckboxChange } from '@angular/material/checkbox';
import { MatAccordion } from '@angular/material/expansion';
import { Store, select } from '@ngrx/store';
import { environment } from 'carehub-environment/environment';
import { BaseComponent } from 'carehub-root/shared/components/base-component';
import {
  SmartListCriteria,
  SmartListResult,
} from 'carehub-root/shared/smartlist';
import * as fromRoot from 'carehub-root/state/app.state';
import {
  PermissionScopes,
  checkPermission,
} from 'carehub-shared/directives/if-allowed.directive';
import {
  ClickGroup,
  ClickGroupType,
  DoubleClickManager,
} from 'carehub-shared/double-click-manager';
import { LookupPipe } from 'carehub-shared/pipes/lookup.pipe';
import * as fromShared from 'carehub-shared/state/index';
import { User } from 'carehub-shared/state/shared.reducer';
import { Utils } from 'carehub-shared/utils';
import { isNumber } from 'lodash';
import { takeUntil } from 'rxjs/operators';
import { IconDetails } from '../icon-details';
import {
  ColumnDetails,
  ColumnTextAlign,
} from '../smartlist-grid/column-details';
import { RowDetails } from '../smartlist-grid/row-details';
import { RowToolTipDetails } from '../smartlist-grid/row-tooltip-details';

/** the type of grid to render. affects click and select behavior */
export enum SelectableType {
  /** default: clicking the row executes the callback (generally a navigation event) */
  None = 'None',
  SingleRow = 'SingleRow',
  SingleRowCheckbox = 'SingleRowCheckbox',
  Checkbox = 'Checkbox',
}

/** behavior enum for click on a multi-select capable grid */
export enum MultiSelectClickMode {
  /** default: clicks should select the specific row and invoke the callback, if any */
  Default = 'Default',
  /** on single click select, on double click invoke the appropriate callback */
  SelectAndCallback = 'SelectAndCallback',
}

export interface GridHandlers<TDomainObject> {
  allowSelection?(
    existingSelections: TDomainObject[],
    newlySelected: TDomainObject
  ): boolean;
}

@Component({
  selector: 'ch-expandable-rows-table',
  templateUrl: './expandable-rows-table.component.html',
  styleUrls: ['./expandable-rows-table.component.scss'],
  providers: [LookupPipe, CdkColumnDef],
})
export class ExpandableRowsTableComponent<TDomainObject>
  extends BaseComponent
  implements OnInit
{
  @ContentChild('itemTemplate') itemTemplate: TemplateRef<any>;
  @ContentChild('rowTemplate') rowTemplate: TemplateRef<any>;
  @ViewChild(MatAccordion) gridAccordion: MatAccordion;
  @Input() columns: ColumnDetails[];
  @Input() rowDetails: RowDetails;
  @Input() headerStyle: RowDetails;
  @Input() rowToolTipDetails: RowToolTipDetails;
  @Input() gridTitle: string;
  @Input() permissionName: string;
  @Input() smartListCriteria: SmartListCriteria;
  @Input() disableAdd = true;
  @Input() allowOpenMultiple = false;
  @Input() showPaginator = true;
  @Input() hideToggle = false;
  @Input() expandFirstRow = false;
  @Input() expandAllRows = false;
  @Input() repeatHeader = false;
  @Output() page = new EventEmitter<{ pageIndex: number; pageSize: number }>();
  @Output() sort = new EventEmitter<{
    sortField: string;
    sortDirection: string;
  }>();
  @Output() rowClicked = new EventEmitter<TDomainObject>();
  @Output() addClick = new EventEmitter<void>();
  @Input() infiniteScroll = false;
  private _smartListResult: SmartListResult<TDomainObject>;
  @Input() set dataSource(
    value: SmartListResult<TDomainObject> | TDomainObject[]
  ) {
    if (Array.isArray(value)) {
      this._smartListResult = {
        currentPage: 1,
        pageCount: 1,
        pageSize: value.length,
        results: value,
        rowCount: value.length,
      };
      this.dataSourceObject = Object.assign(
        {},
        this.dataSourceObject
      ) as SmartListResult<TDomainObject>;
      this.dataSourceObject.results = value;
    } else {
      this._smartListResult = value;
      this.dataSourceObject = value;
    }
    this.isLoading = false;
  }

  @Input() isLoadingInput: boolean = null;
  private isLoading = false;
  currentUser: User;

  expandedElement: TDomainObject;
  dataSourceObject: SmartListResult<TDomainObject>; // Data used to render the rows
  selectedItem: TDomainObject;

  /** flag to suppress the template help message, based on {@link environment.production}. In production like builds (ie all non-local hosted instances), hides the message */
  get showTemplateHelp(): boolean {
    return !environment.production;
  }

  get progressMode() {
    return this.isLoadingInput ||
      this.isLoading ||
      !this.dataSourceObject ||
      this.dataSourceObject.isLoading
      ? 'indeterminate'
      : 'determinate';
  }

  get isReadingAllowed(): boolean {
    const result = checkPermission(
      this.permissionName,
      PermissionScopes.READ,
      this.currentUser
    );
    return result;
  }

  get rowCount() {
    if (this.infiniteScroll) {
      return Number.POSITIVE_INFINITY;
    }

    return this._smartListResult && this._smartListResult.rowCount;
  }

  ColumnTextAlign = ColumnTextAlign; // make enum accessible in view
  @Input() selectAllCheckboxes = false;
  @Output() rowClick = new EventEmitter<TDomainObject>();
  private _displayedColumns: string[];

  @Input() set displayedColumns(value: string[]) {
    this._displayedColumns = value;
  }
  @Input() includedColumns: ColumnDetails[];

  get displayedColumns(): string[] {
    if (this._displayedColumns) {
      return this._displayedColumns;
    } else if (this.includedColumns) {
      return this.includedColumns.map((c) => c.columnDef);
    } else {
      return [];
    }
  }

  @Input() idFieldName: keyof TDomainObject = null;
  @Output() checkboxClick = new EventEmitter<any>();
  @Output() selectionsChanged = new EventEmitter<TDomainObject[]>();
  @Output() deSelectAll = new EventEmitter<any>();
  selection = new SelectionModel<TDomainObject>(true, []);
  @Input() selectableType = SelectableType.None;
  private rowClickManager = new DoubleClickManager(this.unsubscribe$);
  private lastSelection: TDomainObject = null;
  private _datasource: TDomainObject[];
  @Input() handlers: GridHandlers<TDomainObject>;
  @Input() multiSelectClickMode = MultiSelectClickMode.Default;
  @Input() freezeSelection = false;
  @Input() allowSelectAll = false;
  @Input() set datasource(items: TDomainObject[]) {
    this._datasource = items;
    this.lastSelection = null;
  }

  get datasource(): TDomainObject[] {
    if (!this.isReadingAllowed) {
      return [];
    }
    return this._datasource;
  }

  get datasourceLength(): number {
    if (!this.isReadingAllowed || !this.datasource) {
      return 0;
    }
    return this.datasource.length;
  }

  constructor(private store: Store<fromRoot.State>) {
    super();
    this.store
      .pipe(takeUntil(this.unsubscribe$), select(fromShared.getCurrentUser))
      .subscribe((user) => {
        this.currentUser = user;
      });
  }

  ngOnInit() {
    if (
      this.selectableType === SelectableType.Checkbox ||
      this.selectableType === SelectableType.SingleRowCheckbox
    ) {
      this.displayedColumns = ['__select', ...this.displayedColumns];
    }

    this.rowClickManager.clicks$
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((e: ClickGroup<TDomainObject>) => this.handleRowClick(e));
  }

  protected onDestroy(): void {
    this.addClick?.complete();
    this.page?.complete();
    this.sort?.complete();
    this.rowClicked?.complete();
    this.checkboxClick?.complete();
  }
  getCellValueIcons(cellValue: any) {
    return cellValue && cellValue.icons;
  }
  typeOf(column: ColumnDetails, val: any): string {
    // !important: the control type should short-circuit
    // value type checking.

    if (column.type == 'control') {
      return 'control';
    }

    if (val instanceof String || typeof val === 'string') {
      return 'string';
    }

    if (column && column.iconSets) {
      return 'icons';
    }

    if (val instanceof Number) {
      return 'number';
    }

    if (val instanceof Array) {
      return 'array';
    }

    if (val && val.pipe) {
      return 'observable';
    }

    return 'string';
  }
  getIcon(index: number, column: any, iconEntry: any): IconDetails {
    let iconDetails = column.iconSets[index][iconEntry];
    if (!iconDetails) {
      iconDetails = column.iconSets[index]['default'];
    }
    return iconDetails;
  }
  onIconClick(event: any, rowValue: any, iconDetails: IconDetails) {
    if (iconDetails) {
      event.preventDefault();
      event.stopPropagation();

      if (iconDetails.urlFieldName && rowValue[iconDetails.urlFieldName]) {
        throw new Error(
          'Support for url icons has been removed in the expanding grid component'
        );
      }

      if (iconDetails.click) {
        iconDetails.click(rowValue);
      }
    }
  }
  itemRowClicked(item: TDomainObject) {
    this.expandedElement = item;
    this.rowClicked.emit(item);
  }

  onPage(payload: { pageIndex: number; pageSize: number }) {
    this.page.emit(payload);
  }

  onSort(payload: { active: string; direction: string }) {
    let toEmit = {
      sortField: payload.active,
      sortDirection: payload.direction,
    };
    this.sort.emit(toEmit);
  }

  onAdd(event: any): void {
    this.addClick.emit();
  }
  determineLink() {
    let result = null;

    if (this.addClick.observers.length === 0) {
      result = './00000000-0000-0000-0000-000000000000';
    }

    return result;
  }

  // #region collapse/expand apis
  /** collapses all open rows. Required {@link allowOpenMultiple} to be enabled */
  collapseAll(): void {
    if (this.allowOpenMultiple) {
      this.gridAccordion.closeAll();
    } else {
      throw Error(
        "can not use the close all apis when 'allowOpenMultiple' is false"
      );
    }
  }
  // #endregion collapse expand apis

  /** clears all selections and selection context */
  removeAllSelections() {
    this.selection.clear();
    this.lastSelection = null;
  }

  toggleItem(item: TDomainObject): boolean {
    // We need to find the exact row to toggle for the case where it's being removed from selection.
    // After a paging or sorting operation the list will contains objects with different memory addresses
    // so we need to find the correct one by its Id.
    const findById = (existingRow: any) => {
      return existingRow[this.idFieldName] === (<any>item)[this.idFieldName];
    };

    let foundItem = this.selection.selected.find(findById);
    if (!foundItem) {
      // If it hasn't been selected then it wouldn't be in the collection above
      //  so just toggle the one past in.
      foundItem = item;
    }

    let allowSelection = true;

    if (this.isChecked(item) === false) {
      if (this.handlers && this.handlers.allowSelection) {
        if (!this.handlers.allowSelection(this.selection.selected, item)) {
          allowSelection = false;
        }
      }
    }

    if (allowSelection) {
      this.selection.toggle(foundItem);
      if (this.isReadingAllowed) {
        this.selectionsChanged.emit(this.selection.selected);
      }
    }

    return allowSelection;
  }

  onCheckBoxClicked(event: any, row: any, column: any) {
    this.selectAllCheckboxes = false;
    if (event) {
      this.checkboxClick?.emit({
        checked: event,
        row: row,
      });

      if (row && column && column?.controlDef?.setValue) {
        column.controlDef.setValue(row, event);
        const rowSelect = this.selection.selected.findIndex(
          (d) => d[this.idFieldName] === row[this.idFieldName]
        );
        (<any>this.selection.selected[rowSelect])[column.controlDef.name] =
          event.checked;
        this.selectionsChanged.emit(this.selection.selected);
      }
    }
  }

  onRowCheckChange(event: MatCheckboxChange, item: TDomainObject) {
    if (this.selectableType === SelectableType.Checkbox) {
      this.removeAllSelections();
    }
    if (event) {
      if (!this.toggleItem(item)) {
        event.source.checked = false;
      }
    }
  }

  private isSelected(item: TDomainObject): boolean {
    return (
      this.selection.selected.some((selected) => item === selected) != null
    );
  }

  /** row click callback. May either propagate to callback, or toggle grid item. */
  onRowClick(item: TDomainObject, event: MouseEvent) {
    this.rowClickManager.click(event, item);
  }

  private isToggleMode(click: ClickGroup<TDomainObject>): boolean {
    return (
      this.idFieldName &&
      (this.selectableType !== SelectableType.Checkbox ||
        click.type === ClickGroupType.Single ||
        this.multiSelectClickMode === MultiSelectClickMode.Default)
    );
  }

  private isCallbackMode(click: ClickGroup<TDomainObject>): boolean {
    return (
      this.isReadingAllowed &&
      (this.selectableType !== SelectableType.Checkbox ||
        click.type === ClickGroupType.Double ||
        this.multiSelectClickMode === MultiSelectClickMode.Default)
    );
  }

  private handleRowClick(click: ClickGroup<TDomainObject>) {
    if (!this.freezeSelection) {
      if (
        this.selectableType === SelectableType.SingleRow ||
        this.selectableType === SelectableType.SingleRowCheckbox
      ) {
        this.removeAllSelections();
      }
      if (this.isToggleMode(click)) {
        this.toggleRowClicked(click.data, click.click);
      }
      if (this.isCallbackMode(click)) {
        this.rowClick.emit(click.data);
      }
    }
  }
  /** handles row clicks by toggling the selected row. supports holding 'shift' to select all intermediate rows from last selected */
  private toggleRowClicked(item: TDomainObject, event: MouseEvent) {
    // shift key behavior: selects intermediate items (multi-select only)
    this.toggleItem(item);
    if (
      this.isSelected(item) &&
      this.selectableType === SelectableType.Checkbox &&
      event.shiftKey &&
      this.lastSelection
    ) {
      const indexes = [
        this.datasource.indexOf(item),
        this.datasource.indexOf(this.lastSelection),
      ];
      if (indexes.filter((x) => isNumber(x)).length === 2) {
        const start = indexes[0] < indexes[1] ? indexes[0] : indexes[1];
        const end = indexes[0] > indexes[1] ? indexes[0] : indexes[1];
        this.datasource
          .filter((obj, index) => start <= index && index <= end)
          .forEach((x: TDomainObject) => {
            if (!this.isSelected(x)) {
              this.toggleItem(x);
            }
          });
      }
    }
    if (this.isSelected(item)) {
      this.lastSelection = item;
    } else if (this.lastSelection === item) {
      this.lastSelection = null;
    }

    Utils.clearSelection();
  }

  isAllSelected() {
    if (!this._smartListResult) {
      return false;
    }

    const numSelected = this.selection.selected.length;
    const numRows = this._smartListResult.results.length;
    return numSelected === numRows;
  }

  checkboxLabel(row?: TDomainObject): string {
    if (!row) {
      return `${this.isAllSelected() ? 'select' : 'deselect'} all`;
    }
    return `${this.selection.isSelected(row) ? 'deselect' : 'select'}`;
  }

  private isChecked(row: any): boolean {
    if (!this.idFieldName) {
      throw Error(
        `Please provide an 'idFieldName' on your SmartList to enable the checkboxes to be synched properly`
      );
    }

    return this.selection.selected.some(
      (selectedRow: any) =>
        selectedRow[this.idFieldName] === row[this.idFieldName]
    );
  }

  onMasterToggle(event: any) {
    if (this._smartListResult) {
      if (this.isAllSelected()) {
        this.selectAllCheckboxes = false;
        this.selection.clear();
        this.removeAllSelections();
        this.deSelectAll.emit(true);
      } else {
        this.selectAllCheckboxes = true;

        this._smartListResult.results.forEach((row: TDomainObject) =>
          this.selection.select(row)
        );
        this.lastSelection = null;
        if (this.isReadingAllowed) {
          this.selectionsChanged.emit(this.selection.selected);
        }
        this.deSelectAll.emit(false);
      }
    }
  }
}
